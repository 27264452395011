// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-theme-style-guide-src-template-js": () => import("./../node_modules/gatsby-theme-style-guide/src/template.js" /* webpackChunkName: "component---node-modules-gatsby-theme-style-guide-src-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activation-old-js": () => import("./../src/pages/activation-old.js" /* webpackChunkName: "component---src-pages-activation-old-js" */),
  "component---src-pages-collection-old-js": () => import("./../src/pages/collection-old.js" /* webpackChunkName: "component---src-pages-collection-old-js" */),
  "component---src-pages-education-old-js": () => import("./../src/pages/education-old.js" /* webpackChunkName: "component---src-pages-education-old-js" */),
  "component---src-pages-production-old-js": () => import("./../src/pages/production-old.js" /* webpackChunkName: "component---src-pages-production-old-js" */),
  "component---src-templates-page-default-js": () => import("./../src/templates/page-default.js" /* webpackChunkName: "component---src-templates-page-default-js" */),
  "component---src-templates-page-index-js": () => import("./../src/templates/page-index.js" /* webpackChunkName: "component---src-templates-page-index-js" */)
}


export const baseTextStyles = {
  textSm: {
    color: "text",
    fontFamily: "gtAmericaMono",
    fontSize: ["4vw", 0],
    fontWeight: "regular",
    lineHeight: "large",
  },
  textSmFilled: {
    color: "textTitle",
    fontFamily: "gtAmericaMono",
    fontSize: ["4vw", 0],
    fontWeight: "regular",
    lineHeight: "large",
  },
  textBase: {
    color: "text",
    fontFamily: "gtSuperText",
    fontSize: [0, 1],
    fontWeight: "regular",
    lineHeight: "large",
  },
  textLg: {
    color: "text",
    fontFamily: "gtSuperText",
    fontSize: [0, 1, 1, 1, 2],
    fontWeight: "regular",
    lineHeight: "medium",
  },
  textXl: {
    color: "text",
    fontFamily: "gtSuperDisplay",
    fontSize: [1, 3],
    fontWeight: "bold",
    lineHeight: "medium",
  },
  text2Xl: {
    color: "textOther",
    fontFamily: "gtSuperDisplay",
    fontSize: [1, 2, 3, 3, 4],
    fontWeight: "regular",
    lineHeight: "small",
  },
  text3Xl: {
    color: "textTitle",
    fontFamily: "gtSuperDisplay",
    fontSize: [3, 4, 5],
    fontWeight: "bold",
    lineHeight: "small",
  },
  text4Xl: {
    color: "text",
    fontFamily: "gtSuperDisplay",
    fontSize: 6,
    fontWeight: "bold",
    lineHeight: "small",
  },
  textNav: {
    color: "text",
    fontFamily: "gtSuperDisplay",

    fontWeight: "bold",
    lineHeight: "small",
  },
}
